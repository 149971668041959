import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/main'
import HeaderMeta from '../components/HeaderMeta'
import ProgressiveBgImage from '../components/ProgressiveBgImage'
import LoanGrid from '../components/LoanGrid'

class SamlaLan extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const lenders = data.lenders.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <HeaderMeta
          title="Samla lån och krediter | Bli av med dyra små lån | Över 40 banker att söka bland"
          description="Har du för många små lån med höga månadskostnader? Hos oss kan du söka bland ett flertal olika banker som erbjuder förmånliga räntor på samlingslån."
        />
        <ProgressiveBgImage
          hqImage="/images/office-desk.jpg"
          lqImage="/images/office-desk-lq.jpg"
        >
          <section className="section content-box transparent">
            <div className="hero-header-box">
              <h1>Samla dyra små lån och krediter</h1>
              <p>Många av oss har fastnad i kreditfällan med dyra avbetalningar och räntor att betala av varje månad! Lär dig om hur du räddar din ekonomi här - Omstartslån och binda dina krediter!</p>
            </div>
          </section>

          <LoanGrid
            items={lenders.map(({ node }) => {
              return {
                title: node.frontmatter.title || node.fields.slug,
                slug: node.fields.slug,
                image: node.frontmatter.logo,
                rating: node.frontmatter.rating,
                bullet_points: node.frontmatter.bullet_points,
                affiliate_link: node.frontmatter.affiliate_link,
                rating: node.frontmatter.rating,
                age_limit: node.frontmatter.age_limit,
                max_loan_amount: node.frontmatter.max_loan_amount,
                min_loan_amount: node.frontmatter.min_loan_amount,
                min_loan_period: node.frontmatter.min_loan_period,
                max_loan_period: node.frontmatter.max_loan_period,
                is_featured: node.frontmatter.is_featured,
                lender_type: node.frontmatter.lender_type
              }
            })}
          />
          <section className="section content-box">
            <h2>Dyra månadskostnader på grund av kreditköp och lån</h2>
            <p>
              Det finns en fälla som många svenskar faller i, och denna fälla
              skulle vi kunna kalla för kreditfällan. Det är de snabba besluten
              som ofta leder till en kostsam framtid. Ofta så handlar det om att
              man handlar prylar på kredit, har flera olika lån och därmed också
              har många olika räntor att betala av varje månad.
            </p>
            <p>
              De flesta betalar av sina dyra kostnader månatligen, men ser
              aldrig över svart på vitt hur mycket av de pengarna som faktiskt
              går till olika dyra räntor varje månad. När du hamnat i
              kreditfällan så kan det mesta kännas som en evig uppförsbacke, en
              evig nedåtgående spiral som du aldrig riktigt lyckas ta sig ur,
              hur mycket du än försöker! Det finns faktiskt en del saker som du
              kan göra - oavsett vilken din nuvarande situation säger! Det går
              faktiskt att göra en del för att rädda sin privata ekonomi och det
              sa vi gå igenom nedan.
            </p>
            <h2>Samla dina lån - genom att ta ett lån</h2>
            <p>
              Det kan låta mostägelsefullt att man skulle kunna lösa
              kreditfällan med att ta ytterligare ett lån, när man redan har
              problem med att betala av alla de lån och krediter som redan
              existerar. Men det är faktiskt detta som har räddat många
              svenskars privata ekonomi från att falla isär helt. Problemet med
              att ta ett lån, är att bankerna har en del krav på dig som
              låntagare för att du ska få ta ett lån.
            </p>
            <ul>
              <li>
                Du behöver ha en fast inkomst som klarar av att ta ett lån.
              </li>
              <li>Du får ej ha skulder hos Kronofogden.</li>
              <li>Du behöver vara 18 år fyllda och folkbokförd i Sverige.</li>
            </ul>
            <p>
              Ett tips till dig som redan kanske har ett privatlån hos din bank
              är att se över om du kan utöka ditt privatlån för att betala bort
              alla dina andra skulder. På detta sätt så får du bara en enda
              ränta att betala varje månad, och därmed så kan du sänka dina
              månadskostnader avsevärt och ordna upp din ekonomi.
            </p>
            <h2>Om du har skulder hos Kronofogden - Omstartslån </h2>

            <p>
              Ett omstartslån är till för dig som har ett skuldsaldo hos
              Kronofogden fortfarande, men som ändå trots detta har hunnit ordna
              upp din ekonomi och klarar att betala av dina nuvarande utgifter.
              Då man får skulder hos kronofogden så är det just detta som är den
              största nackdelen, att stämpeln ligger kvar så pass länge. Då kan
              ett omstartslån hjälpa, som är en form av skuldsanering. Detta är
              de krav som gäller om du ska ta ett omstartslån:
            </p>
            <ul>
              <li>
                Du behöver ha en bostad som säkerhet för lånet, om du inte har
                detta så kan du se över om banken kan ge dig ett bostadslån som
                en säkerhet för ditt lån.
              </li>
              <li>
                Det lån som du tar måste du använda för att lösa upp dina
                skulder med, så att du är helt skuldfri.
              </li>
              <li>
                Du behöver ha en bra årsinkomst som klarar av att belånas.
              </li>
              <li>
                Din bostad behöver ha ett betydande värde för att vara en
                säkerhet för lånet.
              </li>
            </ul>
            <p>
              Det är alltid knepigt då det gått så långt att man har skulder hos
              Kronofogden, men det är absolut inte omöjligt. Om man inte har
              möjlighet till att göra något av ovanstående, är att se över om
              det finns någon kreditbolag som kan ge lån trots skulder hos
              Kronofogden, och se om man kan hitta ett kreditbolag med vettiga
              villkor och ansöka om ett lån. Och då betala av alla andra skulder
              med det lånet och sedan bara betala en ränta.
            </p>
            <h2>Varför ska jag lösa mina lån mer ett till lån? </h2>
            <p>
              {' '}
              Det kan onekligen låta en aning motsägelsefullt att man skulle
              kunna lösa sina ekonomiska problem med att ta lån. Men det finns
              flera punkter till varför det är en fördel att göra på detta vis,
              som vi går igenom här nedan:
            </p>
            <ul>
              <li>
                Om du har möjlighet att ta ett enda lån hos banken (som i regel
                har en rätt låg ränta) och betala av alla dina nuvarande
                krediter som kostar dig 1000-lappar varje månad i ränta, så får
                du istället en enda låg ränta att betala varje månad.{' '}
              </li>
              <li>
                Genom att göra på detta vis, så kan du få ner dina
                månadskostnader så mycket som det bara är möjligt för dig. Det
                kan vara så stor skillnad på detta, att du knappt kan tro dina
                ögon.{' '}
              </li>
              <li>
                Du ska BARA ta ett lån om din ekonomi tillåter dig att göra
                detta, men om du lever med höga kostnader på grund av krediter -
                så kan detta vara vad som kan rädda din ekonomi!
              </li>
            </ul>
            <p>
              De flesta banker godkänner dig som långivare om du kan visa att du
              är en liten risk att ha som kund och att du kan betala ditt lån
              utan problem. Men om vi leker med tanken att du betalar av 7000 kr
              på olika avbetalningar och räntor, och tar ett lån som tar bort
              allt det som du betalar av - så skulle du kunna få ner
              månadskostnaden med ett par tusen kr.
            </p>
          </section>
        </ProgressiveBgImage>
      </Layout>
    )
  }
}

export default SamlaLan

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    lenders: allMarkdownRemark(
      filter: { frontmatter: { content_type: { eq: "lender" } } }
      sort: { fields: [frontmatter___rating], order: [DESC] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            slug
            title
            description
            featured_image
            logo
            content_type
            is_high_interest_loan
            affiliate_link
            rating
            age_limit
            max_loan_amount
            min_loan_amount
            min_loan_period
            max_loan_period
            affiliate_link
            is_featured
            lender_type
          }
        }
      }
    }
  }
`
